<template>
	<div class="Address" v-title="'门店地址——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<!-- 头部导航栏 -->
		<NavBar></NavBar>
        <div class="box">
            <img class="bran" src="../image/about/address_banner.jpg" alt="" />
        </div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/about/address_banner_mob.jpg"
				alt=""
			/>
		</div>
        <div class="content">
			<div class="nav-list">
                <AboutNav></AboutNav>
            </div>
			<div class="address-list f-y-s-c">
				<div
					v-for="(item, index) in storeList"
					:key="index"
					class="list-item f-y-s-c"
				>
					<div v-if="item.province" class="city f-x-c-c">{{item.province && codeToText[item.province.slice(0,2)] }}</div>
					<img class="img" :src="item.roomCoverPath" alt="" />
					<div class="info f-y-s-s">
						<span class="text1">{{ item.showRoomName }}</span>
						<span class="text2">{{ formartAddress(item) }}</span>
					</div>
					<div class="options f-x-b-c">
						<div @click="novToMap(item)" class="map-btn f-x-c-c">查看地图导航</div>
						<div @click="openDialog()" class="reservation-btn f-x-c-c">预约到店体验</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 底部 -->
		<Footer></Footer>
        <DialogForm ref="refDialogForm" adUnitCode="Z192"></DialogForm>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import AboutNav from '../components/AboutNav.vue'
import {useSalesRoomDict} from '@/utils/frequent.js'
import {codeToText} from 'element-china-area-data'
export default {
	components: {
		NavBar,
		Footer,
		AboutNav,
	},
	data() {
		return {
            codeToText,
			storeList: [
				// {
				// 	address: '上海市奉贤区南奉公路7500号苏宁生活广场3F',
				// 	title: '奉贤店',
				// 	position: {
				// 		lng: '121.495517',
				// 		lat: '30.924118',
				// 	},
				// 	url: require('../image/about/address1.jpg'),
				// },
				// {
				// 	address: '上海市静安区沪太路855号 ',
				// 	title: '静安店',
				// 	position: {
				// 		lng: '121.44574',
				// 		lat: '31.275387',
				// 	},
				// 	url: require('../image/about/address2.jpg'),
				// },
				// {
				// 	address: '河南省郑州市金水区花园路8号正道花园百货3F~5F',
				// 	title: '郑州店',
				// 	position: {
				// 		lng: '113.689338',
				// 		lat: '34.774986',
				// 	},
				// 	url: require('../image/about/address3.jpg'),
				// },
			],
		}
	},
    async mounted(){
        this.storeList = await useSalesRoomDict()
        console.log("this.storeList",this.storeList);
    },
	methods: {
        openDialog(){
            this.$refs.refDialogForm.showDialog('store')
        },
        novToMap(item){
            const address = this.formartAddress(item) 
            // 构建完整的URL，包含搜索参数
            const url = `http://api.map.baidu.com/geocoder?address=${address}&output=html&src=webapp.baidu.openAPIdemo `;
            // 在当前窗口打开该URL
            window.open(url, "_blank");
        },
        formartAddress(data){
            let str
            if(data.province) str = codeToText[data.province.slice(0,2)]
            if(data.city) str = str + codeToText[data.city.slice(0,4)]
            if(data.region) str = str + codeToText[data.region]
            if(data.address) str = str + data.address
            return str
        },

    },
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .Address {
        overflow-x: hidden;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
            flex-flow: column;
			.nav-list {
				width: 100%;
			}
			.address-list {
				width: 100%;
				// background: #FFFFFF;
                padding: 0 12px 20px 12px;
                box-sizing: border-box;
				.list-item {
					height: 330px;
					width: 100%;
					margin-top: 20px;
					box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
					border-radius: 9px;
                    position: relative;
                    background: #fff;
                    .city{
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        // width: 60px;
                        padding: 0 8px;
                        height: 25px;
                        border-radius: 15px;
                        background: rgba(0, 0, 0, 0.5);
                        
                        font-weight: bold;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
					.img {
						width: 100%;
						height: 220px;
						object-fit: cover;
						border-radius: 9px 9px 0 0;
					}
					.info {
						width: 100%;
						padding: 10px 12px 0 12px;
						box-sizing: border-box;
						.text1 {
							
							font-weight: bold;
							font-size: 16px;
							color: #241934;
							line-height: 16px;
						}
						.text2 {
							
							font-weight: 400;
							font-size: 12px;
							color: #241934;
							line-height: 12px;
							margin-top: 10px;
						}
					}
					.options {
						margin-top: 10px;
						width: 100%;
						padding: 0 12px;
						box-sizing: border-box;
						.map-btn {
							width: 190px;
							height: 40px;
							border-radius: 30px;
							border: 1px solid #e5e5e5;

							
							font-weight: 400;
							font-size: 14px;
							color: #241934;
							margin-right: 10px;
                            cursor: pointer;
						}
						.reservation-btn {
                            cursor: pointer;
							width: 190px;
							height: 40px;
							background: #753cbe;
							border-radius: 30px;

							
							font-weight: 400;
							font-size: 14px;
							color: #ffffff;
						}
					}
				}
			}
		}
    }
}
@media screen and (min-width: 900px) and(max-width:1359px) {
	.Address {
		height: 100%;
		.box{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 60px 60px 180px 60px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.nav-list {
				width: 440px;
				height: 679px;
				margin-right: 40px;
			}
			.address-list {
				// width: 1062px;
                flex: 1;
				// background: #FFFFFF;

				.list-item {
					height: 1100px;
					width: 100%;
					margin-bottom: 56px;
					box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
					border-radius: 18px;
                    position: relative;
                    background: #fff;
                    .city{
                        position: absolute;
                        top: 40px;
                        left: 40px;
                        width: 142px;
                        height: 60px;
                        border-radius: 30px;
                        background: rgba(0, 0, 0, 0.5);
                        
                        font-weight: bold;
                        font-size: 30px;
                        color: #FFFFFF;
                    }
					.img {
						width: 100%;
						height: 700px;
						object-fit: cover;
						border-radius: 18px 18px 0 0;
					}
					.info {
						width: 100%;
						padding: 30px 40px;
						box-sizing: border-box;
						.text1 {
							
							font-weight: bold;
							font-size: 48px;
							color: #241934;
							line-height: 48px;
						}
						.text2 {
							
							font-weight: 400;
							font-size: 28px;
							color: #241934;
							line-height: 28px;
							margin-top: 30px;
						}
					}
					.options {
						margin-top: 50px;
						width: 100%;
						padding: 0 40px;
						box-sizing: border-box;
						.map-btn {
							width: 441px;
							height: 121px;
							border-radius: 60px;
							border: 1px solid #e5e5e5;

							
							font-weight: 400;
							font-size: 40px;
							color: #241934;
							margin-right: 80px;
                            cursor: pointer;
						}
						.reservation-btn {
                            cursor: pointer;
							width: 440px;
							height: 120px;
							background: #753cbe;
							border-radius: 60px;

							
							font-weight: 400;
							font-size: 40px;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}
@media screen and (min-width: 1360px) {
	.Address {
		height: 100%;
		.box{
            width: 100%;
			height: 420px;
			// margin-top: 96px;
        }
        .box-mob{
            display: none;
        }
		.bran {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.content {
			padding: 60px 60px 180px 60px;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			background: #f6f6f6;
			.nav-list {
				width: 440px;
				height: 679px;
				margin-right: 40px;
			}
			.address-list {
				width: 1062px;
				// background: #FFFFFF;

				.list-item {
					height: 1100px;
					width: 100%;
					margin-bottom: 56px;
					box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
					border-radius: 18px;
                    position: relative;
                    background: #fff;
                    .city{
                        position: absolute;
                        top: 40px;
                        left: 40px;
                        width: 142px;
                        height: 60px;
                        border-radius: 30px;
                        background: rgba(0, 0, 0, 0.5);
                        
                        font-weight: bold;
                        font-size: 30px;
                        color: #FFFFFF;
                    }
					.img {
						width: 100%;
						height: 700px;
						object-fit: cover;
						border-radius: 18px 18px 0 0;
					}
					.info {
						width: 100%;
						padding: 30px 40px;
						box-sizing: border-box;
						.text1 {
							
							font-weight: bold;
							font-size: 48px;
							color: #241934;
							line-height: 48px;
						}
						.text2 {
							
							font-weight: 400;
							font-size: 28px;
							color: #241934;
							line-height: 28px;
							margin-top: 30px;
						}
					}
					.options {
						margin-top: 50px;
						width: 100%;
						padding: 0 40px;
						box-sizing: border-box;
						.map-btn {
							width: 441px;
							height: 121px;
							border-radius: 60px;
							border: 1px solid #e5e5e5;

							
							font-weight: 400;
							font-size: 40px;
							color: #241934;
							margin-right: 80px;
                            cursor: pointer;
						}
						.reservation-btn {
                            cursor: pointer;
							width: 440px;
							height: 120px;
							background: #753cbe;
							border-radius: 60px;

							
							font-weight: 400;
							font-size: 40px;
							color: #ffffff;
						}
					}
				}
			}
		}
	}
}
</style>
